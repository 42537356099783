import React, { useState } from "react";
import { Link, navigate } from "gatsby";

import PasswordStrengthBar from "react-password-strength-bar";
import PasswordChecklist from "react-password-checklist";

import H1 from "../../components/htmlElements/h1";
import Layout from "../../components/layout";
import UserPool from "../../components/auth/UserPool";
import InputWrapper from "../../components/submitElements/inputWrapper";
import Button from "../../components/htmlElements/button";

const passwordRequirementsText =
  "Password must contain at least 1 uppercase, 1 lowercase, 1 special character and 1 number. The password must be minimal 8 characters long.";

const defaultRegistrationMessage =
  "Your registration has been successfully, please check your email to activate your account.";

export default function SignupPage() {
  const [status, setStatus] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = () => {
    if (newPassword === newPassword2) {
      UserPool.signUp(emailAddress, newPassword, [], null, (err, data) => {
        let newStatus = "";
        let newErrorMessage = "";

        // Do not show that an account already exists as this indicates the user does exist.
        // This is more secure and better privacy.

        if (err) {
          if (
            err.message === "An account with the given email already exists."
          ) {
            newStatus = defaultRegistrationMessage;
          } else {
            newErrorMessage = `Registration error: ${err.message}`;
          }

          setErrorMessage(newErrorMessage);
        } else {
          navigate("/login/activate");
        }

        // setStatus(newStatus);
      });
    } else {
      setErrorMessage("Passwords don't match.");
    }
  };

  const displayError = errorMessage ? (
    <div className="mt-4 border border-red/50 bg-red/10 p-1 pl-2 text-sm rounded">
      <p>{errorMessage}</p>
    </div>
  ) : (
    ""
  );

  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7">
          <H1 innerContent="Create an account" additionalClasses="pb-5" />

          {status && status !== "" ? (
            <p>{status}</p>
          ) : (
            <>
              <p className="text-sm">
                Please note{" "}
                <a
                  className="underline"
                  href="/login-help/#activate"
                  target="_blank"
                >
                  the whole process
                </a>{" "}
                might take a couple of minutes. Already have an account?{" "}
                <Link className="underline" to="/login">
                  Login here.
                </Link>
              </p>

              {displayError}

              <form className="mt-5">
                <InputWrapper title="Email address:">
                  <input
                    type="text"
                    className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
                    required
                    value={emailAddress}
                    placeholder="john@doe.com"
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </InputWrapper>

                <InputWrapper
                  title="New password"
                  infoBoxContent={passwordRequirementsText}
                >
                  <input
                    type="password"
                    name="password"
                    className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
                    required
                    value={newPassword}
                    placeholder="*********"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </InputWrapper>

                <InputWrapper title="Verify new password">
                  <input
                    type="password"
                    name="password2"
                    className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
                    required
                    value={newPassword2}
                    placeholder="*********"
                    onChange={(e) => setNewPassword2(e.target.value)}
                  />
                </InputWrapper>

                <InputWrapper title="Password validation">
                  <PasswordStrengthBar password={newPassword} minLength={5} />
                  <PasswordChecklist
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                    ]}
                    minLength={8}
                    value={newPassword}
                    valueAgain={newPassword2}
                  />
                </InputWrapper>

                <Button
                  additionalClasses="primaryBackgroundColor"
                  innerContent="Signup"
                  color="blue"
                  type="button"
                  onClick={handleSubmit}
                />
              </form>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}
